import React from "react"
import Newsletter from "../components/newsletter"
import logo from "../images/logo.png"
import "./index.scss"

import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaPhoneSquare,
  FaPaperPlane,
  FaLinkedin,
} from "react-icons/fa"
const IndexPage = () => (
  <section>
    <img src={logo} alt="" />
    <div>
      <h1>Brophy Brothers Wholesale</h1>

      <p>
        Our website is currently under construction, in the meantime, you can
        reach us on the various platforms below.
      </p>

      <ul className="social">
        <li>
          <a href="tel:9144235546">
            <FaPhoneSquare />
          </a>
        </li>
        <li>
          <a href="mailto:contact@brophybrothers.net">
            <FaPaperPlane />
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/brophybrothers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebookSquare />
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/brophybrothers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/brophybrothers"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitterSquare />
          </a>
        </li>

        <li>
          <a
            href="https://www.linkedin.com/company/brophy-brothers-wholesale-fruit-&-vegetable"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
        </li>
      </ul>

      <Newsletter />
    </div>
  </section>
)

export default IndexPage
