import React, { Component } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import ReCAPTCHA from "react-google-recaptcha"

import "./newsletter.module.scss"
export default class newsletter extends Component {
  state = {
    email: "",
    msg: null,
    isVerified: false,
  }

  onChange = response => {
    console.log(response)
    this.setState({ isVerified: true })
  }
  handleSubmit = async evt => {
    evt.preventDefault()

    if (this.state.isVerified) {
      const result = await addToMailchimp(this.state.email)
      this.setState({ msg: result.msg })
      setTimeout(() => {
        this.setState({ email: "", msg: null })
      }, 3000)
    } else {
      this.setState({ msg: "Please verify you are human." })
      setTimeout(() => {
        this.setState({ msg: null })
      }, 3000)
    }
  }

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value,
    })
  }
  render() {
    return (
      <form method="post" onSubmit={this.handleSubmit}>
        <label htmlFor="emailAddress">
          <p>Subscribe to get notified when we go live!</p>
        </label>
        <input
          type="email"
          name="email"
          id="emailAddress"
          required
          value={this.state.email}
          onChange={this.handleChange}
          placeholder="Email"
        />
        <ReCAPTCHA
          sitekey="6Le-h7gUAAAAAOUAHYr3etE5i-jWNf5HmHGOIAFS"
          onChange={this.onChange}
        />
        <input type="submit" value="Submit" />
        <span>{this.state.msg}</span>
      </form>
    )
  }
}
